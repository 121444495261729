import React, { useCallback, useEffect, useMemo } from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import TagManager from 'react-gtm-module'
import Navigation from "./Components/Navigation/Navigation";
import Main from "./Components/Main/Main";
import Signin from "./Components/Signin/Signin";
import Profile from "./Components/Profile/Profile";
import "./App.css";

function App() {
  const localServer = false
  const backendServerAddress = localServer ? "http://localhost:1974/account" : "https://informedpoker.com:1973/account";

  const tagManagerArgs = {
    gtmId: 'G-LW3873WJ26'
  }

  TagManager.initialize(tagManagerArgs)
  
  const userLogInStatus = useMemo(
    () => ({
      isLoggedIn: false,
    }),
    []
  );

  let jsonDataClass = {
    jsonData: {},
  };

  const signOut = useCallback(async () => {
    if (userLogInStatus.isLoggedIn === false) {
      return;
    }

    try {
      const response = await fetch(backendServerAddress, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "SignOut",
        }),
      });

      if (!response.ok) {
        throw new Error("Error occurred while signing out from account");
      }

      const signOutjsonData = await response.json();

      if (signOutjsonData.status !== "OK") {
        alert("There was an error signing out from your account");
      }
    } catch (error) {
      console.error(error);
    }

    userLogInStatus.isLoggedIn = false;
  }, [userLogInStatus, backendServerAddress]);

  useEffect(
    (userLogInStatus) => {
      const handleBeforeUnload = (event) => {
        event.preventDefault();
        signOut(userLogInStatus);
        event.returnValue = "";
      };

      window.addEventListener("beforeunload", handleBeforeUnload);

      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    },
    [signOut]
  );

  return (
    <div>
      <BrowserRouter>
        <Navigation userLogInStatus={userLogInStatus} signOut={signOut} />
        <Routes>
          <Route
            exact
            path="/"
            element={<Main userLogInStatus={userLogInStatus} />}
          />
          <Route
            exact
            path="/signin"
            element={
              <Signin
                userLogInStatus={userLogInStatus}
                jsonDataClass={jsonDataClass}
                backendServerAddress={backendServerAddress}
              />
            }
          />
          <Route
            exact
            path="/profile"
            element={
              <Profile
                userLogInStatus={userLogInStatus}
                jsonDataClass={jsonDataClass}
                backendServerAddress={backendServerAddress}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
