import React from "react";
import "katex/dist/katex.min.css";
import { InlineMath } from "react-katex";
import googlePlayBadge from "./Images/download-google.webp";
import appStoreBadge from "./Images/download-apple.webp";
import pokerTable2 from "./Images/poker-table2.jpg";
import screenshotLoginScreen from "./Images/screenshot_login_screen.png";
import screenshotMainScreen from "./Images/screenshot_main_screen.png";
import screenshotMainScreenBlue from "./Images/screenshot_main_screen_blue.png";
import screenshotMainScreenRed from "./Images/screenshot_main_screen_red.png";
import screenshotPlayerMenu from "./Images/screenshot_player_menu.png";
import "./Main.css";

const Main = () => {
  return (
    <>
      <section className="about-paragraph-picture">
        <div className="about-paragraph">
          <p className="about-p">
            <i>i</i>Poker (<i>informed</i> Poker) is a free online Texas Hold'em
            poker app that <b>shows your equity</b> while you play with other
            players. You can use this information to
            make <b>more <i>informed</i> decisions</b>, hence the name.
          </p>
          <p className="about-p">
            <b>This is a social game, and no real money is involved in any form, i.e.,
            all the bet amounts and winnings or losses are in digital chips, not real
            money.</b>
          </p>
          <p className="about-p">
            The app helps you improve your poker skills by showing your
            winning probabilities as a percentage, calculated based on your hole
            cards and the community cards. The app keeps updating this number during
            each hand, with an initial number shown when you are dealt your hole
            cards, and then updated as new community cards are dealt.
          </p>
        </div>
        <div className="picture-container">
          <img className="picture" src={pokerTable2} alt="poker" />
        </div>
      </section>
      <section className="download-section">
        <a href="https://play.google.com/store/apps/details?id=com.pp.ipoker">
          <img
            className="google-play-icon"
            alt="Get it on Google Play"
            src={googlePlayBadge}
          />
        </a>
        <a href="https://apps.apple.com/us/app/ipoker/id1601867265">
          <img
            className="app-store-icon"
            alt="Download on the App Store"
            src={appStoreBadge}
          />
        </a>
      </section>
      <div className="container">
        <div className="content">
          <div>
            <h2>Contents</h2>
            <ol>
              <li>
                <a className="email-hyperlink" href="#equity">
                  Equity and Maximum Call Amount
                </a>
              </li>
              <li>
                <a className="email-hyperlink" href="#account">
                  Account Info
                </a>
              </li>
              <li>
                <a className="email-hyperlink" href="#settings">
                  Game Settings
                </a>
              </li>
              <li>
                <a className="email-hyperlink" href="#tournaments">
                  Tournaments
                </a>
              </li>
              <li>
                <a className="email-hyperlink" href="#private">
                  Private Tables
                </a>
              </li>
              <li>
                <a className="email-hyperlink" href="#install">
                  Install Link
                </a>
              </li>
            </ol>
          </div>
          <img
            src={screenshotMainScreen}
            alt="iPoker Main Screen"
            style={{ width: "1024px" }}
          />
          <h2 className="title" id="equity">
            Equity and Maximum Call Amount
          </h2>
          <p>
            The above figure shows a sample snapshot of the main screen of the
            app, where the equity is shown as a percentage next to the hand
            score (High Card) in the bottom left of the screen, above the
            "CHECK" button. Equity is the expected winnings expressed as a
            percentage of pot. In most cases, this is roughly equal to your
            probability of winning the current hand.
          </p>
          <p>
            Mathematically, equity can be written as
            <div className="math-equation">
              <InlineMath math="e = \frac{1}{N} \sum_c \sum_o \left( \chi_w + \sum_{t_i} \frac{1}{n_i} \chi_i \right)," />
            </div>
            where:
            </p>
            <ul>
              <li>
                <InlineMath math="c" /> denotes any possible combination of the
                remaining community cards,
              </li>
              <li>
                <InlineMath math="o" /> denotes any possible combination of the
                opponents' cards,
              </li>
              <li>
                <InlineMath math="N" /> denotes the total number of combinations
                of the remaining community cards and opponents' cards,
              </li>
              <li>
                <InlineMath math="\chi_w" /> denotes the indicator function for
                the winning event,
              </li>
              <li>
                <InlineMath math="t_i" /> denotes any tie event with this player
                being one of the tied players,
              </li>
              <li>
                <InlineMath math="n_i" /> denotes the number of tied players in
                the tie event <InlineMath math="t_i" />, and
              </li>
              <li>
                <InlineMath math="\chi_i" /> denotes the indicator function for
                the tie event <InlineMath math="t_i" />.
              </li>
            </ul>
          <p>
            The app also shows the largest call/bet amount that results in a positive
            expected return (if such largest amount exists). In the figure, this
            is the number in the box to the right of the equity. If all call/bet
            amounts result in positive expected returns (i.e., when you have a
            large equity), this box is not shown on the screen. When this box is
            shown on the screen, betting as little as possible results in the
            largest expected return, and calling an amount larger than the number
            shown in this box results in a negative expected return.
          </p>
          <p>
            Mathematically, the condition for having a positive expected return
            can be written as
            <div className="math-equation">
              <InlineMath math="b \lt \frac{eP}{1 - e(n + 1)}\ ," />
            </div>
            where:
            </p>
            <ul>
              <li>
                <InlineMath math="b" /> denotes the bet amount,
              </li>
              <li>
                <InlineMath math="e" /> denotes the equity,
              </li>
              <li>
                <InlineMath math="P" /> denotes the current pot amount, and
              </li>
              <li>
                <InlineMath math="n" /> denotes the number of opponent players
                in the game.
              </li>
            </ul>
          <p>
            Note that the above statements are probabilistic, meaning that they
            should be interpreted as fractions or average winnings over a large
            number of trials. For example, a preflop equity of 85% for pocket
            aces with two players means that if the same hand (of pocket aces)
            is played a large number of times (e.g., one million times), then
            roughly 85% of the times (i.e., roughly 850,000 times out of those
            one million times), the pocket aces will win the pot. In other
            words, even in this very favorable scenario, there is a 15% chance
            of losing (150,000 out of the one million in the example). In
            general, even with very high equity numbers, a given hand may end up
            losing in a single trial.
          </p>
          <h2 className="title" id="account">
            Account Info
          </h2>
          <img
            src={screenshotLoginScreen}
            alt="iPoker Login Screen"
            style={{ width: "1024px" }}
          />
          <p>
            You can play this app simply by choosing a screen name. Your
            winnings and other stats (such as hands played and biggest win) will
            be available to you as long as you continue using the same device
            with the same account info. You can optionally add a login name and
            password when you create your screen name, in which case, you will
            be able to carry your winnings to any other device.
          </p>
          <h2 className="title" id="settings">
            Game Settings
          </h2>
          <img
            src={screenshotPlayerMenu}
            alt="iPoker Player Menu"
            style={{ width: "1024px" }}
          />
          <p>
            You can select the stakes (small blind, big blind, and buy-in), as
            well as the game speed (relaxed, normal, or fast). The buy-in
            amounts range from as low as 1000 to as high as 1,000,000,000.
          </p>
          <img
            src={screenshotMainScreenBlue}
            alt="iPoker Main Screen, Medium Stakes"
            style={{ width: "1024px" }}
          />
          <img
            src={screenshotMainScreenRed}
            alt="iPoker Main Screen, High Stakes"
            style={{ width: "1024px" }}
          />
          <p>
            When you first join, you receive 100,000 free chips. You can buy
            more chips at any time or get promotional free chips by watching a
            short video or inviting a friend.
          </p>
          <h2 className="title" id="tournaments">
            Tournaments
          </h2>
          <p>
            Both cash games and tournaments are available. You can join a cash
            game by clicking on the PLAY button or join a tournament by clicking
            on the TOURNAMENT button. The game settings (table stakes and game
            speed) apply to both cash games and tournaments.
          </p>
          <h2 className="title" id="private">
            Private Tables
          </h2>
          <p>
            You can let the server pick a table for you based on your
            selections, or you can create or join a private table to play with
            your friends.
          </p>
          <h2 className="title" id="install">
            Install Link
          </h2>
          <p>
            You can install the app&nbsp;from&nbsp;
            <a href="https://play.google.com/store/apps/details?id=com.pp.ipoker">
              Google Play
            </a>
            &nbsp;store&nbsp;or&nbsp;
            <a href="https://apps.apple.com/us/app/ipoker/id1601867265">
              App Store
            </a>
            .
          </p>
          <a
            className="google-icon-button"
            href="https://play.google.com/store/apps/details?id=com.pp.ipoker"
          >
            <img alt="Get it on Google Play" src={googlePlayBadge} />
          </a>
          <br />
          <a
            className="apple-icon-button"
            href="https://apps.apple.com/us/app/ipoker/id1601867265"
          >
            <img alt="Download on the App Store" src={appStoreBadge} />
          </a>
          <br />
          <br />
          <p>
            For more information, contact{" "}
            <a className="email-hyperlink" href="mailto:info@informedpoker.com">
              info@informedpoker.com
            </a>
          </p>
          <hr />
        </div>
      </div>
    </>
  );
};

export default Main;
