import React from "react";
import { useNavigate } from "react-router-dom";
import icon from "./icon.png";
import "./Logo.css";

const Logo = () => {
  const navigate = useNavigate();

  return (
    <div className="ma4 mt0">
      <div className="logo br2" style={{ height: "150px", width: "150px" }}>
        <div className="logo-inner">
          <img
            src={icon}
            alt="logo"
            onClick={() => navigate("/")}
            style={{ cursor: "pointer" }}
          />
        </div>
      </div>
    </div>
  );
};

export default Logo;
