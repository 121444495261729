import React from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../Logo/Logo";
import "./Navigation.css";

const Navigation = ({ userLogInStatus }) => {
  const navigate = useNavigate();

  const pathname = window.location.pathname;
  const word = pathname.substring(1);

  if (word === "profile" || word === "signin") {
    return (
      <div className="header">
        <div className="left">
          <Logo />
        </div>
        <div className="middle">
          <h2 className="app">
            The&nbsp; <i>i</i>Poker App
          </h2>
        </div>
      </div>
    );
  } else if (userLogInStatus.isLoggedIn) {
    return (
      <div className="header">
        <div className="left">
          <Logo />
        </div>
        <div className="middle">
          <div className="app">
            The <i>i</i>Poker App
          </div>
        </div>
        <div className="right">
          <button className="signin" onClick={() => navigate("/profile")}>
            Profile
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <div className="header">
        <div className="left">
          <Logo />
        </div>
        <div className="middle">
          <div className="app">
            The <i>i</i>Poker App
          </div>
        </div>
        <div className="right">
          <button className="signin" onClick={() => navigate("/signin")}>
            Sign In
          </button>
        </div>
      </div>
    );
  }
};

export default Navigation;
