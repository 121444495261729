import React from "react";
import { useNavigate } from "react-router-dom";
import profileBanner from "./profile-banner.jpg";

const Profile = ({ userLogInStatus, jsonDataClass, backendServerAddress }) => {
  const navigate = useNavigate();

  function clearBrowserHistory() {
    window.history.replaceState(null, null, window.location.href);
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      window.history.go(1);
    };
  }

  const signOut = async () => {
    try {
      const response = await fetch(backendServerAddress, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "SignOut",
        }),
      });

      if (!response.ok) {
        alert("Error occurred while signing out from account");
        return;
      }

      const signOutjsonData = await response.json();
      if (signOutjsonData.status === "OK") {
        userLogInStatus.isLoggedIn = false;
        clearBrowserHistory();
        navigate("/");
      } else {
        alert("There was an error signing out from your account");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deleteProfile = async () => {
    // Display the confirmation dialog
    const confirmed = window.confirm(
      "Are you sure you want to delete your profile?"
    );

    if (confirmed) {
      try {
        const response = await fetch(backendServerAddress, {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            action: "Delete",
          }),
        });

        if (!response.ok) {
          alert("Error occurred while deleting account");
          return;
        }

        const deleteJsonData = await response.json();
        if (deleteJsonData.status === "OK") {
          alert("Account successfully deleted");
          userLogInStatus.isLoggedIn = false;
          clearBrowserHistory();
          navigate("/");
        } else {
          alert("Error occurred while deleting account");
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const jsonData = jsonDataClass.jsonData;
  return (
    <section className="tc pa3 pa5-ns">
      <article className="hide-child relative ba b--black-10 mw7 shadow-5 center">
        <div className="welcome-message">
          <h2>Welcome {jsonData.name}</h2>
        </div>
        <img src={profileBanner} className="db" alt=""/>
        <div
          className="pa2 bt b--black-20"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <p style={{ marginBottom: "20px" }}/>
          <table>
            <tr>
              <td align="right"><b>Name:</b></td>
              <td></td>
              <td align="left"><font color="blue"><b>{jsonData.name}</b></font></td>
            </tr>
            <tr>
              <td align="right"><b>id:</b></td>
              <td></td>
              <td align="left"><font color="blue"><b>{jsonData.id.toLocaleString('en', {useGrouping:true})}</b></font></td>
            </tr>
            <tr>
              <td align="right"><b>Bank:</b></td>
              <td></td>
              <td align="left"><font color="blue"><b>{jsonData.bank.toLocaleString('en', {useGrouping:true})}</b></font></td>
            </tr>
            <tr>
              <td align="right"><b>Hands Played:</b></td>
              <td></td>
              <td align="left"><font color="blue"><b>{jsonData.handsPlayed.toLocaleString('en', {useGrouping:true})}</b></font></td>
            </tr>
            <tr>
              <td align="right"><b>Biggest Win:</b></td>
              <td></td>
              <td align="left"><font color="blue"><b>{jsonData.biggestWin.toLocaleString('en', {useGrouping:true})}</b></font></td>
            </tr>
            <tr>
              <td align="right"><b>Bonus Chips:</b></td>
              <td></td>
              <td align="left"><font color="blue"><b>{jsonData.bonusChips.toLocaleString('en', {useGrouping:true})}</b></font></td>
            </tr>
          </table>
          <p style={{ marginBottom: "20px" }}/>
          <button
            className="link tc ph3 pv1 db bg-animate  white f6 br1"
            style={{
              width: "300px",
              backgroundColor: "#2f6b4d",
              marginBottom: "10px",
              padding: "10px",
            }}
            onClick={() => signOut()}
          >
            Sign out
          </button>
          <button
            className="link tc ph3 pv1 db bg-animate  white f6 br1"
            style={{
              width: "300px",
              backgroundColor: "#2f6b4d",
              padding: "10px",
              marginBottom: "10px",
            }}
            onClick={deleteProfile}
          >
            Delete Profile
          </button>
        </div>
      </article>
    </section>
  );
};

export default Profile;
