import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "tachyons";
import "./Signin.css";

const Signin = ({ userLogInStatus, jsonDataClass, backendServerAddress }) => {
  const [loginName, setLoginName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const signInSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(backendServerAddress, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "SignIn",
          loginName: loginName,
          password: password,
        }),
      });

      if (!response.ok) {
        setError("Error occurred while submitting data");
        return;
      }

      const jsonData = await response.json();
      jsonDataClass.jsonData = jsonData;

      if (jsonData.status !== "OK") {
        if (jsonData.status === "PasswordIncorrect") {
          setError(
            "Invalid password. Please check your password and try again."
          );
        } else if (jsonData.status === "AccountDoesNotExist") {
          setError(
            "Account does not exist. Please check your username and try again."
          );
        } else {
          setError("Error occured while submitting data");
        }
        return;
      }

      userLogInStatus.isLoggedIn = true;
      navigate("/profile");
    } catch (error) {
      console.error("Error occurred while submitting data", error);
    }
  };

  return (
    <article className="br3 ba b--black-10 mv4 w-100 w-50-m w-25-l mw6 shadow-5 center">
      <main className="pa4 black-80">
        <form className="measure" onSubmit={signInSubmit}>
          <fieldset id="sign_up" className="ba b--transparent ph0 mh0">
            <div className="flex justify-center">
              <legend className="f1 fw6 ph0 mh0">Sign In</legend>
            </div>
            {error && ( // Conditional rendering of error message
              <div className="login-error-message" style={{ color: "#D8000C" }}>
                <p>{error}</p>
              </div>
            )}
            <div className="mt3">
              <label className="db fw6 lh-copy f6" htmlFor="email-address">
                Login Name
              </label>
              <input
                className="pa2 input-reset ba bg-transparent hover-bg-black hover-white w-100"
                id="login-name"
                type="text"
                name="login-name"
                value={loginName}
                onChange={(e) => setLoginName(e.target.value)}
                required
              />
            </div>
            <div className="mv3">
              <label className="db fw6 lh-copy f6" htmlFor="password">
                Password
              </label>
              <input
                className="b pa2 input-reset ba bg-transparent hover-bg-black hover-white w-100"
                id="password"
                type="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
          </fieldset>
          <div className="flex justify-center">
            <input
              className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib"
              type="submit"
              value="Sign in"
            />
          </div>
          <div className="lh-copy mt3"></div>
        </form>
      </main>
    </article>
  );
};

export default Signin;
